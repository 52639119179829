import { arrayOf, func, shape, string } from 'prop-types';
import React from 'react';
import iconAmex from '../../assets/img/icon-amex-logo.svg';
import iconCreditCard from '../../assets/img/icon-credit-card.svg';
import iconMastercard from '../../assets/img/icon-mastercard-logo.svg';
import iconVisa from '../../assets/img/icon-visa-logo.svg';
import { formatCardNumber } from '../../config/utils';

export const CardsList = ({ cards, selectedCard, setSelectedCard }) => (
  <>
    <div className="cards-list">
      {cards.map((card) => {
        let cardBrandLogo;
        const cardBrand = card.brand.toLowerCase();

        if (cardBrand === 'amex' || cardBrand === 'american express') {
          cardBrandLogo = iconAmex;
        } else if (cardBrand === 'mastercard' || cardBrand === 'maestro') {
          cardBrandLogo = iconMastercard;
        } else if (cardBrand === 'visa') {
          cardBrandLogo = iconVisa;
        } else {
          cardBrandLogo = iconCreditCard;
        }

        return (
          <button
            key={card.uuid}
            className={`cards-list__item ${
              card.uuid === selectedCard.uuid && 'cards-list__item--selected'
            }`}
            type="button"
            onClick={() => setSelectedCard(card)}
          >
            <img className="item__logo" src={cardBrandLogo} alt="" />
            {formatCardNumber(card.truncatedCardNumber)}
          </button>
        );
      })}
    </div>
  </>
);

CardsList.propTypes = {
  cards: arrayOf(
    shape({
      uuid: string,
      token: string,
      truncatedCardNumber: string,
    }),
  ).isRequired,
  selectedCard: shape({ uuid: string }).isRequired,
  setSelectedCard: func.isRequired,
};

export default CardsList;
