import { useStripe } from '@stripe/react-stripe-js';
import { func, number, shape, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { BottomSheet } from 'react-spring-bottom-sheet';
import api from '../../api/api';
import baseRoutes from '../../api/base-routes';
import lockIcon from '../../assets/img/icon-lock.svg';
import iconPlus from '../../assets/img/icon-plus.svg';
import iconSpinner from '../../assets/img/loading.png';
import iconSuccess from '../../assets/img/success.svg';
import { paymentStates } from '../../config/constants';
import { AddCard } from './AddCard';
import { CardsList } from './CardsList';

export const Payment = ({ hardwareId, finishPayment, stationFee }) => {
  const stripe = useStripe();
  const [cards, setCards] = useState();
  const [selectedCard, setSelectedCard] = useState({});
  const [modal, setModal] = useState(false);
  const [state, setState] = useState(paymentStates.INIT);
  const [cardsPending, setCardsPending] = useState(true);

  const fetchCards = async () => {
    const { data } = await api.get(baseRoutes.card);
    data.reverse();
    setCards(data);

    if (data.length) {
      setSelectedCard(data[0]);
    }

    setCardsPending(false);
  };

  const resolveAdd = () => {
    fetchCards();
    setModal(false);
  };

  useEffect(() => {
    const alertUser = (e) => {
      e.preventDefault();
      e.returnValue = '';
    };

    fetchCards();

    window.addEventListener('beforeunload', alertUser);

    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, []);

  const createPaymentIntent = async () => {
    try {
      setState(paymentStates.PENDING);

      const { data: leaseInitResult } = await api.post(`${baseRoutes.lease}/init`, {
        stationLink: hardwareId,
        cardId: selectedCard.uuid,
      });

      if (leaseInitResult.cardPayment) {
        const { paymentIntent } = await stripe.confirmCardPayment(
          leaseInitResult.cardPayment.clientSecret,
        );

        if (!paymentIntent || paymentIntent?.status !== 'requires_capture') {
          toast.error('Error occurred during payment confirmation');
          finishPayment();

          return;
        }
      }

      await api.post(`${baseRoutes.lease}/process/${leaseInitResult.lease.id}`);

      setState(paymentStates.FINISHED);

      setTimeout(() => {
        finishPayment();
      }, 2000);
    } catch (error) {
      if (error && error.response) {
        toast.error(error.response.data.error);

        finishPayment();
      }
    }
  };

  return (
    <>
      <div className="lease__info">
        <div className="info__text">Price per hour</div>
        <div className="info__price">
          {(Math.round(stationFee.station.location.price.rateFee) / 100).toFixed(2)} {stationFee.currency}
        </div>
      </div>
      <div className="lease__label">Choose a card</div>
      <div className="lease__cards">
        {cardsPending ? (
          <div className="loading">
            <img src={iconSpinner} alt="" className="spinner-icon" />
          </div>
        ) : (
          <>
            <CardsList
              cards={cards}
              selectedCard={selectedCard}
              setSelectedCard={setSelectedCard}
            />
          </>
        )}

        <button type="button" className="cards-list__item" onClick={() => setModal(true)}>
          <img className="item__logo" src={iconPlus} alt="" />
          Add a new card
        </button>
      </div>
      <div className="lease__bottom">
        {state === paymentStates.INIT && (
          <button
            disabled={!stripe || !cards?.length}
            type="button"
            className="lease__pay"
            onClick={createPaymentIntent}
          >
            <img src={lockIcon} alt="" className="pay__icon lock-icon" />
            <span className="pay__text">Unlock powerbank</span>
          </button>
        )}

        {state === paymentStates.PENDING && (
          <div className="lease__pay lease__pay-loading">
            <img src={iconSpinner} alt="" className="pay__icon spinner-icon" />
            <span className="pay__text">Unlocking</span>
          </div>
        )}

        {state === paymentStates.FINISHED && (
          <div className="lease__pay lease__pay-success">
            <img src={iconSuccess} alt="" className="pay__icon success-icon" />
            <span className="pay__text">Unlocked</span>
          </div>
        )}
      </div>
      <BottomSheet
        open={modal}
        onDismiss={() => setModal(false)}
        snapPoints={({ minHeight }) => minHeight}
      >
        <div className="add-card-modal__wrapper">
          <AddCard resolveAdd={resolveAdd} />
        </div>
      </BottomSheet>
    </>
  );
};

Payment.propTypes = {
  hardwareId: string.isRequired,
  finishPayment: func.isRequired,
  stationFee: shape({
    hourlyRate: number,
    currency: string,
  }).isRequired,
};

export default Payment;
