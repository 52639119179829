import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/auth';
import toast from 'react-hot-toast';
import { apiUrl } from '../config/constants';

const api = axios.create({
  baseURL: apiUrl,
});

api.interceptors.request.use(async (params) => {
  const token = await firebase.auth().currentUser?.getIdToken();
  const newParams = params;
  if (token) {
    newParams.headers.Authorization = `Bearer ${token}`;
  }
  return newParams;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response.status === 401) {
      toast.error('Login session expired');

      firebase.auth().signOut();
    }

    return Promise.reject(error);
  },
);

export default api;
