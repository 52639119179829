import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { Route, Switch, Redirect } from 'react-router-dom';
import PrivateRoute from './components/auth/PrivateRoute';
import './config/firebase';
import useAuth from './hooks/useAuth';
import Home from './pages/Home';
import LeaseFlow from './pages/LeaseFlow';
import Login from './pages/Login';
import Adyen from "./pages/Adyen";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const App = () => {
  const [hardwareId, setHardwareId] = useState('');
  const { isSignedIn, pending } = useAuth();

  return (
    <Elements stripe={stripePromise}>
      <Switch>
        <Route path="/session">
          <Adyen />
        </Route>
        <PrivateRoute path="/lease" auth={{ isSignedIn, pending }}>
          <LeaseFlow hardwareId={hardwareId} setHardwareId={setHardwareId} />
        </PrivateRoute>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/checkout">
          <Home hardwareId={hardwareId} setHardwareId={setHardwareId} />
        </Route>
        <Route path="/">
          <Redirect to="checkout" />
        </Route>
      </Switch>
      <Toaster position="top-center" />
    </Elements>
  );
};

export default App;
