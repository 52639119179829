const isHardwareIdValid = (hardwareId) => {
  const regexp = /^[A-Z]{4}[0-9]{12}$/;

  return regexp.test(hardwareId);
};

export const getHardwareId = (search) => {
  const query = new URLSearchParams(search);
  const hardwareId = query.get('stid');

  if (isHardwareIdValid(hardwareId)) {
    return hardwareId;
  }

  return '';
};

export const formatCardNumber = (cardNumber) => cardNumber.match(/.{4}/g).join(' ');

export const isMobile = {
  Android: () => navigator.userAgent.match(/Android/i),
  iOS: () => navigator.userAgent.match(/iPhone|iPad|iPod/i),
  any: () => isMobile.Android() || isMobile.iOS(),
};

export const getCountryEmoji = (countryCode) => {
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map((char) => 127397 + char.charCodeAt());
  return String.fromCodePoint(...codePoints);
};

export const escapeRegex = (string) => string.replace(/[-/\\^$*+?.()|[\]{}]/g, '');
