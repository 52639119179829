/* eslint-disable react/jsx-props-no-spreading */
import { bool, node, shape, string } from 'prop-types';
import React from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';

const PrivateRoute = ({ children, redirectPath, auth, ...rest }) => {
  const history = useHistory();

  if (auth.pending) {
    return (
      <h1
        style={{
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          color: '#B7C0E6',
          fontWeight: 500,
        }}
      >
        Loading...
      </h1>
    );
  }

  return (
    <Route
      {...rest}
      render={() =>
        auth.isSignedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: redirectPath,
              state: { referrer: history.location.pathname },
            }}
          />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  children: node.isRequired,
  redirectPath: string,
  auth: shape({
    isSignedIn: bool,
    pending: bool,
  }).isRequired,
};

PrivateRoute.defaultProps = {
  redirectPath: '/login',
};

export default PrivateRoute;
