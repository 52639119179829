import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { func } from 'prop-types';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import api from '../../api/api';
import baseRoutes from '../../api/base-routes';
import iconSpinner from '../../assets/img/loading.png';

export const AddCard = ({ resolveAdd }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [pending, setPending] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setPending(true);

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);

    try {
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (error) {
        toast.error(error.message);
        setPending(false);

        return;
      }

      if (paymentMethod) {
        await api.post(baseRoutes.card, {
          cardToken: paymentMethod.id,
        });
      }

      setPending(false);
      resolveAdd();
    } catch (error) {
      toast.error(error.message);
      setPending(false);
    }
  };

  const elementStyles = {
    base: {
      color: '#424770',
      fontWeight: 600,
      fontFamily: 'Space Grotesk, Quicksand, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',

      ':focus': {
        color: '#000059',
      },

      '::placeholder': {
        color: '#9BACC8',
      },

      ':focus::placeholder': {
        color: '#CFD7DF',
      },
    },
    invalid: {
      color: '#FA755A',
      ':focus': {
        color: '#FA755A',
      },
      '::placeholder': {
        color: '#FFCCA5',
      },
    },
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="card-data">
        <div className="lease-label">Card Number</div>
        <CardNumberElement
          options={{ style: elementStyles }}
          className="card-element card-number"
        />
        <div className="row">
          <div style={{ marginRight: '16px' }}>
            <div className="lease-label">Expiration</div>
            <CardExpiryElement
              options={{ style: elementStyles }}
              className="card-element card-expiry"
            />
          </div>
          <div>
            <div className="lease-label">CVV/CVC</div>
            <CardCvcElement options={{ style: elementStyles }} className="card-element card-cvc" />
          </div>
        </div>
      </div>
      <button type="submit" disabled={!stripe} className="add-button">
        {pending ? <img src={iconSpinner} alt="" className="pay__icon spinner-icon" /> : 'Add card'}
      </button>
    </form>
  );
};

AddCard.propTypes = {
  resolveAdd: func.isRequired,
};

export default AddCard;
