import React, { useEffect } from 'react';
import '@adyen/adyen-web/dist/adyen.css';
import AdyenCheckout from '@adyen/adyen-web';
import api from '../api/api';
import baseRoutes from '../api/base-routes';

const AdyenPage = () => {
  useEffect(() => {
    const pageParams = Object.fromEntries(
      new URLSearchParams(window.location.search).entries(),
    );

    const { authToken, action } = pageParams;

    const requestBody = {};

    if (action === 'top-up') {
      requestBody.topupTemplateId = pageParams.topupTemplateId;
    } else if (action === 'save-card') {
      requestBody.paymentMethod = pageParams.paymentMethod;
    }

    api
      .post(
        `${baseRoutes.adyen}/session/${action}`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        },
      )
      .then(({ data }) => data)
      .then((sessionData) => {
        const clientKey = process.env.REACT_APP_ADYEN_CLIENT_KEY;
        const environment = clientKey.substring(0, clientKey.indexOf('_'));

        const configuration = {
          environment,
          clientKey,
          session: {
            id: sessionData.id,
            sessionData: sessionData.sessionData,
          },
          onPaymentCompleted: (result, component) => {
            console.info(result, component);
            window.location.href = `https://lectogo.page.link/1ECG`;
          },
          onError: (error, component) => {
            console.error(error.name, error.message, error.stack, component);
            window.location.href = `https://lectogo.page.link/1ECG`;
          },
        };

        AdyenCheckout(configuration).then((res) => {
          res.create('dropin').mount('#dropin-container');
        });
      });
  }, []);

  return <div id="dropin-container" />;
};

export default AdyenPage;
