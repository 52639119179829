import { func, string } from 'prop-types';
import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import bannerImage from '../assets/img/banner-image.svg';
import iconGooglePlay from '../assets/img/icon-google-play.svg';
import iconAppStore from '../assets/img/icon-apple.svg';
import { getHardwareId, isMobile } from '../config/utils';
import './Home.css';
import { stores } from '../config/constants';

const Home = ({ hardwareId, setHardwareId }) => {
  const history = useHistory();
  const storeIcon = (isMobile.Android() && iconGooglePlay) || (isMobile.iOS() && iconAppStore);
  const storeLink =
    (isMobile.Android() && stores.playStore) ||
    (isMobile.iOS() && stores.appStore) ||
    stores.browser;

  useEffect(() => {
    if (!hardwareId) {
      setHardwareId(getHardwareId(history.location.search));

      history.replace(history.location.pathname);
    }
  }, [hardwareId, setHardwareId, history]);

  return (
    <div className="banner" style={{ height: window.innerHeight }}>
      <div className="banner__wrapper">
        <div className="banner__text">Time to unlock your powerbank</div>
        <div className="banner__image">
          <img src={bannerImage} alt="" />
        </div>
        <div className="banner__links">
          <a className="banner__link" href={storeLink} target="_blank" rel="noopener noreferrer">
            {isMobile.any() && <img src={storeIcon} alt="" />}
            <span>Open app</span>
          </a>
          <Link className="banner__link" to="/lease">
            <span>Continue in browser</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

Home.propTypes = {
  hardwareId: string.isRequired,
  setHardwareId: func.isRequired,
};

export default Home;
