export const apiUrl = process.env.REACT_APP_API_URL;

export const leaseStatus = {
  RELEASE_FAILED: 'RELEASE_FAILED',
  RETURNED: 'RETURNED',
  PENDING_PAYMENT: 'PENDING_PAYMENT',
  PAYMENT_FAILED: 'PAYMENT_FAILED',
  FULFILLED: 'FULFILLED',
  STOLEN: 'STOLEN',
  PAID: 'PAID',
  REQUIRES_ADDITIONAL_STEPS: 'REQUIRES_ADDITIONAL_STEPS',
};

export const stationStatus = {
  OFF: 'OFF',
  ON: 'ON',
};

export const paymentStates = {
  INIT: 'INIT',
  PENDING: 'PENDOING',
  FINISHED: 'FINISHED',
};

export const stores = {
  appStore: process.env.REACT_APP_IOS_LINK,
  playStore: process.env.REACT_APP_ANDROID_LINK,
  browser: process.env.REACT_APP_DEFAULT_LINK,
};

export const legalLinks = {
  termsOfUse: 'https://www.lectogo.com/terms-of-use-en',
  privacyPolicy: 'https://www.lectogo.com/privacy-policy-en',
};
