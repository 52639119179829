import firebase from 'firebase/app';
import 'firebase/auth';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import api from '../api/api';
import baseRoutes from '../api/base-routes';
import appleIcon from '../assets/img/icon-apple.svg';
import facebookIcon from '../assets/img/icon-facebook.svg';
import googleIcon from '../assets/img/icon-google.svg';
import PhoneAuth from '../components/auth/PhoneAuth';
import SocialLogin from '../components/auth/SocialLogin';
import { legalLinks } from '../config/constants';
import './Login.css';

export default () => {
  const history = useHistory();
  const [loginStep, setLoginStep] = useState(1);
  const [hide, setHide] = useState(false);

  const setSocialLogin = (state) => {
    setHide(!!state);
  };

  const resolveLogin = async () => {
    try {
      await api.post(baseRoutes.auth);

      history.replace(history?.location?.state?.referrer || '/checkout');
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="login">
      <div className="login__top">
        <div className="login__heading">
          {loginStep === 1 ? "What's your number?" : "What's the code?"}
        </div>
        <div className="login__phone">
          <PhoneAuth
            resolveLogin={resolveLogin}
            setLoginStep={setLoginStep}
            setSocialLogin={setSocialLogin}
          />
        </div>
        {loginStep === 1 && !hide && (
          <div className="login__social">
            <SocialLogin
              resolveLogin={resolveLogin}
              icon={googleIcon}
              buttonText="Sign in with Google"
              provider={new firebase.auth.GoogleAuthProvider()}
            />
            <SocialLogin
              resolveLogin={resolveLogin}
              icon={facebookIcon}
              buttonText="Sign in with Facebook"
              provider={new firebase.auth.FacebookAuthProvider()}
            />
            <SocialLogin
              resolveLogin={resolveLogin}
              icon={appleIcon}
              buttonText="Sign in with Apple"
              provider={new firebase.auth.OAuthProvider('apple.com')}
            />
          </div>
        )}
      </div>
      <div className="login__bottom">
        <div className="login__legal">
          By creating an account, you agree to the Lectogo{' '}
          <a target="_blank" rel="noopener noreferrer" href={legalLinks.termsOfUse}>
            Terms of Service
          </a>{' '}
          and{' '}
          <a target="_blank" rel="noopener noreferrer" href={legalLinks.privacyPolicy}>
            Privacy policy
          </a>
        </div>
      </div>
    </div>
  );
};
