import firebase from 'firebase/app';
import 'firebase/auth';
import { func, shape, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import './Auth.css';

const SocialLogin = ({ icon, buttonText, provider, resolveLogin }) => {
  const [authProvider, setAuthProvider] = useState();

  useEffect(() => {
    setAuthProvider(provider);
  }, [provider]);

  const signIn = async () => {
    try {
      await firebase.auth().signInWithPopup(authProvider);

      resolveLogin();
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <div className="social">
        <button type="button" onClick={signIn}>
          <img src={icon} className="social__icon" alt="" />
          <span className="social__text">{buttonText}</span>
        </button>
      </div>
    </>
  );
};

SocialLogin.propTypes = {
  icon: string.isRequired,
  buttonText: string.isRequired,
  provider: shape({}).isRequired,
  resolveLogin: func.isRequired,
};

export default SocialLogin;
