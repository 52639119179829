import { number, shape, string } from 'prop-types';
import React, { useEffect, useState } from 'react';

const Lease = ({ lease }) => {
  const extractTime = new Date(lease.extractTime);
  const [cost, setCost] = useState(0);
  const [rentTime, setRentTime] = useState('');
  const [hourProgress, setHourProgress] = useState(0);
  const [untilNextHour, setUntilNextHour] = useState('');
  const oneHourMs = 3600000;

  const updateTime = () => {
    const now = new Date();
    const diff = new Date(now - new Date(lease.extractTime));
    const hourDiffMs = diff.getTime() % oneHourMs;
    const untilNext = new Date(oneHourMs - hourDiffMs);

    setHourProgress(100 - (hourDiffMs / oneHourMs) * 100);
    setUntilNextHour(
      `${untilNext.getMinutes()}:${untilNext.getSeconds().toString().padStart(2, 0)}`,
    );

    diff.setMinutes(diff.getMinutes() + diff.getTimezoneOffset());
    setRentTime(`${diff.getHours()}h:${diff.getMinutes().toString().padStart(2, 0)}m`);
    setCost((diff.getHours() * lease.fee + lease.fee) / 100);
  };

  useEffect(() => {
    updateTime();
    const interval = setInterval(() => {
      updateTime();
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="lease-list__item">
      <div className="item__meta">
        <div className="col">
          <div className="meta__entry">
            <div className="lease__label">Rented at</div>
            <div className="meta__value">
              {extractTime.getHours()}:{extractTime.getMinutes().toString().padStart(2, 0)}{' '}
              {extractTime.getDate()}/{extractTime.getMonth() + 1}
            </div>
          </div>
          <div className="meta__entry">
            <div className="lease__label">Total charged</div>
            <div className="meta__value">
              {cost} {lease.currency}
            </div>
          </div>
        </div>
        <div className="col">
          <div className="meta__entry">
            <div className="lease__label">Rented from</div>
            <div className="meta__value">{lease.sourceLocation.name}</div>
          </div>
          <div className="meta__entry">
            <div className="lease__label">Total rent time</div>
            <div className="meta__value">{rentTime}</div>
          </div>
        </div>
      </div>
      <div className="item__progress">
        <div className="lease__label">Time until next charge:</div>
        <div
          className="progress-bar"
          style={{
            background: `linear-gradient(90deg, #0690FF, #0690FF ${hourProgress}%, #D8DCE5 ${hourProgress}%, #D8DCE5 100%)`,
          }}
        >
          {untilNextHour}
        </div>
      </div>
      <div className="item__info">
        For every {lease.pricingPolicy.rateIncrementInMinutes} minutes you rent the powerbank you
        will be charged{' '}
        <b>
          {lease.pricingPolicy.rateFee / 100} {lease.currency}
        </b>
        . If not returned within 24 hours you will be charged{' '}
        <b>
          {lease.pricingPolicy.stolenPrice / 100} {lease.currency}
        </b>
        !
      </div>
    </div>
  );
};

Lease.propTypes = {
  lease: shape({
    fee: number,
    currency: string,
    extractTime: string,
  }).isRequired,
};

export default Lease;
