import { func, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import countries from '../../assets/countries.json';
import { escapeRegex, getCountryEmoji } from '../../config/utils';
import './PhoneInput.css';

const PhoneInput = ({ label, onChange, setSocialLogin }) => {
  const [selectedCountry, setSelectedCountry] = useState({ alpha2: 'SE', code: '46' });
  const [number, setNumber] = useState('');
  const [modal, setModal] = useState(false);
  const [focused, setFocused] = useState(false);
  const [searchCountry, setSearchCountry] = useState('');

  useEffect(() => {
    const language = navigator.language.toString();
    const formattedLanguage = language.substring(0, 2);
    const languagesMap = {
      da: {
        alpha2: 'DK',
        code: '45',
      },
      nb: {
        alpha2: 'NO',
        code: '47',
      },
      fi: {
        alpha2: 'FI',
        code: '385',
      },
      default: {
        alpha2: 'SE',
        code: '46',
      },
    };
    setSelectedCountry(languagesMap[formattedLanguage] ?? languagesMap.default);
  }, [setSelectedCountry]);

  useEffect(() => {
    onChange({
      countryCode: selectedCountry.code,
      fullPhoneNumber: `+${selectedCountry.code}${number}`,
      phoneNumber: number,
    });
  }, [number, onChange, selectedCountry]);

  return (
    <div className="phone">
      <div className="phone__label">{label}</div>
      <div className={`phone__input ${focused ? 'phone__input-focused' : ''}`}>
        <div className="input input__country">
          <button type="button" onClick={() => setModal(true)}>
            <span className="country__flag">{getCountryEmoji(selectedCountry.alpha2)}</span>
            &nbsp;
            <span className="country__code">+{selectedCountry.code}</span>
          </button>
        </div>
        <div className="input input__number">
          <input
            onFocus={() => {
              setSocialLogin(true);
              setFocused(true);
            }}
            onBlur={() => {
              setSocialLogin(false);
              setFocused(false);
            }}
            type="tel"
            value={number}
            onChange={(e) => {
              setNumber(e.target.value);
            }}
          />
        </div>
      </div>
      <BottomSheet
        open={modal}
        onDismiss={() => setModal(false)}
        snapPoints={({ maxHeight }) => [maxHeight * 0.8, maxHeight]}
      >
        <div className="modal__wrapper">
          <div className="wrapper__heading">Select your country</div>
          <input
            type="text"
            style={{
              opacity: 0,
              height: 0,
            }}
            onFocus={(e) => e.target.blur()}
          />
          <input
            className="wrapper__search"
            type="text"
            placeholder="Search"
            onChange={(e) => setSearchCountry(escapeRegex(e.target.value.toLowerCase()))}
          />
          <ul className="wrapper__list">
            {countries
              .filter((country) => {
                if (!searchCountry) {
                  return true;
                }

                const countryNameMatch = country.name.toLowerCase().search(searchCountry) !== -1;
                const countryCodeMatch = country.e164_cc.search(searchCountry) !== -1;

                return countryNameMatch || countryCodeMatch;
              })
              .map((country) => (
                <li className="list__item" key={country.display_name}>
                  <button
                    type="button"
                    onClick={() => {
                      setSelectedCountry({
                        alpha2: country.iso2_cc,
                        code: country.e164_cc,
                      });
                      setModal(false);
                    }}
                  >
                    <span className="item__flag">{getCountryEmoji(country.iso2_cc)}</span>
                    {country.name} (+{country.e164_cc})
                  </button>
                </li>
              ))}
          </ul>
        </div>
      </BottomSheet>
    </div>
  );
};

PhoneInput.propTypes = {
  onChange: func,
  setSocialLogin: func,
  label: string,
};

PhoneInput.defaultProps = {
  onChange: () => {},
  setSocialLogin: () => {},
  label: 'Phone number',
};

export default PhoneInput;
