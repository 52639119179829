import React, { useEffect, useState } from 'react';
import api from '../../api/api';
import baseRoutes from '../../api/base-routes';
import { leaseStatus } from '../../config/constants';
import Lease from './Lease';

export default () => {
  const [leases, setLeases] = useState([]);

  useEffect(() => {
    const fetchLeases = async () => {
      const { data } = await api.get(`${baseRoutes.user}/lease`);

      setLeases(data.filter((lease) => lease.status === leaseStatus.FULFILLED));
    };

    fetchLeases();
  }, []);

  return leases.length ? (
    <div className="lease-list">
      {leases.map((lease) => (
        <Lease lease={lease} key={lease.id} />
      ))}
    </div>
  ) : (
    <div className="no-leases">
      No active rentals. <br /> Try to refresh the page.
    </div>
  );
};
