import firebase from 'firebase/app';
import 'firebase/auth';
import { func } from 'prop-types';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import OtpInput from 'react-otp-input';
import PhoneInput from '../phone/PhoneInput';
import iconSpinner from '../../assets/img/loading.png';

const PhoneAuth = ({ resolveLogin, setLoginStep, setSocialLogin }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [continueDisabled, setContinueDisabled] = useState(true);
  const [confirmationCode, setConfirmationCode] = useState('');
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [phoneConfirmationResult, setPhoneConfirmationResult] = useState();
  const [recaptchaVerifier, setRecaptchaVerifier] = useState();
  const [resendDisabled, setResendDisabled] = useState(false);
  const [resendCountdown, setResendCountdown] = useState(60);
  const [pending, setPending] = useState(false);

  useEffect(() => {
    const recaptcha = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      size: 'invisible',
    });

    setRecaptchaVerifier(recaptcha);

    return () => {
      recaptcha.clear();
    };
  }, []);

  const startCountdown = () => {
    setResendDisabled(true);
    const countdownTimer = setInterval(() => {
      setResendCountdown((countdown) => {
        if (countdown <= 0) {
          setResendDisabled(false);
          clearInterval(countdownTimer);
          return 60;
        }

        return countdown - 1;
      });
    }, 1000);
  };

  const phoneSignIn = async () => {
    try {
      setPending(true);
      const confirmationResult = await firebase
        .auth()
        .signInWithPhoneNumber(phoneNumber, recaptchaVerifier);

      setPhoneConfirmationResult(confirmationResult);
      setIsCodeSent(true);
      setLoginStep(2);

      setPending(false);
    } catch (error) {
      toast.error(error.message);
      setPending(false);
    }
  };

  const confirmPhoneSignIn = async () => {
    try {
      setPending(true);
      await phoneConfirmationResult.confirm(confirmationCode);

      resolveLogin();
      setPending(false);
    } catch (error) {
      toast.error(error.message);
      setPending(false);
    }
  };

  return (
    <>
      {!isCodeSent ? (
        <div className="phone-login">
          <PhoneInput
            setSocialLogin={setSocialLogin}
            onChange={(value) => {
              setPhoneNumber(value.fullPhoneNumber);
              setContinueDisabled(!value.phoneNumber);
            }}
          />
          <button
            disabled={continueDisabled}
            className="login__continue"
            type="button"
            onClick={phoneSignIn}
          >
            {pending ? (
              <img src={iconSpinner} alt="" className="pay__icon spinner-icon" />
            ) : (
              'Continue'
            )}
          </button>
        </div>
      ) : (
        <div className="phone-otp">
          <div className="phone-otp__label">Verification Code</div>
          <OtpInput
            className="phone-otp__input"
            numInputs="6"
            isInputNum
            shouldAutoFocus
            containerStyle={{ width: '100%', justifyContent: 'space-between' }}
            inputStyle={{
              width: '80%',
              height: '48px',
              outline: 'none',
              border: '2px solid transparent',
              background: '#F2F4FA',
              borderRadius: '12px',
              color: 'black',
              fontSize: '24px',
              fontWeight: '700',
            }}
            focusStyle={{ border: '2px solid #0092FF' }}
            onChange={(val) => {
              setConfirmationCode(val);
            }}
            value={confirmationCode}
          />
          <button
            type="button"
            className="phone-otp__resend"
            disabled={resendDisabled}
            onClick={() => {
              phoneSignIn();
              startCountdown();
            }}
          >
            Resend code
          </button>
          {resendDisabled && (
            <div className="phone-otp__countdown">
              You can try resend the code after {resendCountdown} seconds.
            </div>
          )}
          <button
            disabled={!confirmationCode}
            className="login__continue"
            type="button"
            onClick={confirmPhoneSignIn}
          >
            {pending ? (
              <img src={iconSpinner} alt="" className="pay__icon spinner-icon" />
            ) : (
              'Continue'
            )}
          </button>
        </div>
      )}
      <div id="recaptcha-container" />
    </>
  );
};

PhoneAuth.propTypes = {
  resolveLogin: func.isRequired,
  setLoginStep: func.isRequired,
  setSocialLogin: func.isRequired,
};

export default PhoneAuth;
