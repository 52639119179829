import { func, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import api from '../api/api';
import baseRoutes from '../api/base-routes';
import LeaseList from '../components/lease/LeaseList';
import { Payment } from '../components/payment/Payment';
import { stationStatus } from '../config/constants';
import './LeaseFlow.css';

const LeaseFlow = ({ hardwareId, setHardwareId }) => {
  const [rentalStep, setRentalStep] = useState(0);
  const [stationFee, setStationFee] = useState({});
  const history = useHistory();

  useEffect(() => {
    let errorFlag = false;
    const fetchStationFee = async () => {
      try {
        const { data } = await api.get(`${baseRoutes.station}/${hardwareId}/fee`, {
          params: { appType: 'WEB_APP' },
        });

        if (data.stationStatus === stationStatus.OFF) {
          toast('Station is offline', {
            icon: '😞',
          });

          errorFlag = true;
        }

        setStationFee(data);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          toast.error('Invalid station link');

          errorFlag = true;
        } else {
          toast.error('Network error');
          history.push('/');
        }
      }
    };

    if (hardwareId) {
      fetchStationFee().then(() => {
        setRentalStep(!errorFlag ? 1 : 2);
      });
    } else {
      setRentalStep(2);
    }
  }, [hardwareId, history]);

  useEffect(() => {
    if (rentalStep === 2) {
      setHardwareId('');
    }
  }, [rentalStep, setHardwareId]);

  const finishPayment = () => {
    setRentalStep(2);
  };

  return (
    <div className="lease">
      <div className="lease__heading">
        {rentalStep === 1 ? 'Rent a powerbank' : 'Active rentals'}
      </div>
      {rentalStep === 1 && (
        <>
          <Payment hardwareId={hardwareId} finishPayment={finishPayment} stationFee={stationFee} />
        </>
      )}
      {rentalStep === 2 && <LeaseList />}
    </div>
  );
};

LeaseFlow.propTypes = {
  hardwareId: string.isRequired,
  setHardwareId: func.isRequired,
};

export default LeaseFlow;
